<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a Option") }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 400px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
                        <v-card
              v-for="(fm, index) in item.options"
              :key="index"
              class="mt-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="11"
                  >
                    <translatable-text-field
                      v-model="fm.title"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.title"
                      :label="$t('Name')"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    >
                    </translatable-text-field>
                  </v-col>
                  <v-col
                    cols="1"
                  >
                    <v-icon
                      color="danger"
                      @click.stop="removeOption(index, fm)"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="8"
                  >
                    <v-text-field
                      v-model="fm.code"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.code"
                      :label="$t('Code')"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      v-model="fm.price"
                      dense
                      hide-details
                      type="number"
                      outlined
                      :label="$t('Price')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <translatable-textarea
                      v-model="fm.description"
                      dense
                      hide-details
                      type="number"
                      outlined
                      :label="$t('Description')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            color="info"
            class="me-3"
            @click="addOption"
          >
            {{ $t("Add Option") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import IForm from '@/components/IForm'
import IAutocomplete from '@/components/IAutocomplete'
import TranslatableTextField from '@/components/TranslatableTextField'
import TranslatableTextarea from '@/components/TranslatableTextarea.vue'

export default {
  components: {
    TranslatableTextarea,
    TranslatableTextField,
    IAutocomplete,
    IForm,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormtestActive = ref(false)
    const loading = ref(false)
    const options = ref([])

    const isLoadingOptions = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    console.log('item', item.value.options)

    // item.value.options = item.value.options || []

    const option = computed({
      get: () => ({ name: { en: search.value, fr: search.value }, shop_id: item.value.shop_id }),
    })

    watch(item, val => {
      console.log('options')
      if (item.value.user && options.value.findIndex(ele => ele.id === item.user_id) < 0) {
        // options.value.push(item.value.user)
      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeOption = (index, at) => {
      item.value.options.splice(index, 1)

      if (at.option_id != null && at.value_id != null) {
        axios.post(`/transporters/${at.id}`)
      }
    }
    const addOption = () => {
      item.value.options = item.value.options || []
      item.value.options.push({
        price: 0, title: '', code: '', description: {},
      })
      console.log("options", item.value.options)
    }
    const formatDate = (date, f) => format(date, f)
    const newTest = () => {
      item.value.user = {
        name: { en: search.value, fr: search.value }, email: null, tell: null,
      }
      isFormtestActive.value = true
    }

    const searchOptions = term => {
      axios.get('/admin/options', { params: { term, rowsPerPage: 100, shop: item.value.shop_id || 0 } }).then(response => {
        isLoadingOptions.value = false

        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = options.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            options.value.push(u)
          }
        }
      })
    }
    const updateoptions = test => {
      isFormtestActive.value = false
      options.value.push(test)
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        axios.post('/admin/transporters', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data

            // emit('update:is-form-active', false)

            emit('cancel')
            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    searchOptions('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingOptions.value) return ''
      isLoadingOptions.value = true
      searchOptions(val)

      return ''
    })
    const shopIdChanged = computed({
      get: () => item.value.shop_id,
    })

    watch(shopIdChanged, val => {
      searchOptions()

      return ''
    })

    // onMounted(searchParticular)

    return {
      option,
      minDate,
      resetItem,
      form,
      isFormtestActive,
      search,
      options,
      updateoptions,
      newTest,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,
      avatarText,
      addOption,
      removeOption,
      isLoadingOptions,

      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
